import React from 'react';
import Logo from './blocks/Logo';
import './App.css';
import {
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import NavBar from './blocks/NavBar';
import Divider from './blocks/Divider';
import Loading from './blocks/Loading';
import loadable from "@loadable/component";

const LoadableHome = loadable(() => import("./views/pages/home"), {
  fallback: <Loading />
});

const LoadableCoinDetail = loadable(() => import("./views/pages/coin"), {
  fallback: <Loading />
});

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html,
  body {
    font-family: Georgia, Cambria, "Times New Roman", Times,
      "Microsoft Yahei", serif;
    color: rgba(0, 0, 0, 0.8);
  }
  body {
    min-height: 100vh;
  }
`;
const TopNavDivider = styled.div`
  height: 65px;
  margin-bottom: 0;
`;

function App() {
  let location = useLocation();

  return (
    <div>
      <GlobalStyle />
      <NavBar>
        <div>
          <Link to="/">
            {location.pathname === '/'
              ? <Logo.Long />
              : <Logo.Short />
            }
          </Link>
        </div>
      </NavBar>
      <TopNavDivider />

      <Divider />

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        {/* <Route path="/about">
          <About />
        </Route> */}
        <Route path="/c/:coinSymbol">
          <LoadableCoinDetail />
        </Route>
        <Route path="/">
          <LoadableHome />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
