import React from "react";
import styled from "styled-components";

const Loading = () => (
  <div>
    Loading...
  </div>
);

export default Loading;
