import styled from "styled-components";

const Divider = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 1032px;
  margin: 24px auto;
`;

export default Divider;
