import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.nav`
  position: fixed;
  display: block;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 0.54);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);

  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
    transition: transform 300ms ease;
  }
`;

const Inner = styled.div`
  position: relative;
  max-width: 1032px !important;
  height: 65px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  justify-content: space-between !important;
  display: flex !important;
  align-items: center !important;
`;

const NavBar = ({ children }) => (
  <Wrapper>
    <Inner>{children}</Inner>
  </Wrapper>
);

export default NavBar;
